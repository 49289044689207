import React, { useEffect, useState } from "react";
// import logo from "./logo.svg";
import "./App.css";
import { StyledAnimation } from "./styled";
import { motion, Variants } from "framer-motion";
import {
 EiLocation,
 FlowbiteMapLocationOutline,
 ZondiconsLocation,
} from "./assets";
import SmoothScroll from "./SmoothScroll.component";
import Framer1Component from "./framer1";

const cardVariants1_5: Variants = {
 offscreen: {
  y: 500,
  opacity: 0,
  // rotateY: -180,
 },
 onscreen: {
  y: 0,
  opacity: 1,
  // scale: 1,
  transition: {
   type: "spring",

   duration: 0.5,
   stiffness: 15,
  },
 },
};
const cardVariants1_4: Variants = {
 offscreen: {
  x: 1000,
  opacity: 0,
 },
 onscreen: {
  x: 10,
  opacity: 1,
  transition: {
   type: "spring",
   duration: 0.5,
   stiffness: 10,
  },
 },
};
const cardVariants1_3: Variants = {
 offscreen: {
  x: "-100vw",
  opacity: 0,
  // rotateY: -180,
 },
 onscreen: {
  x: -10,
  opacity: 1,
  // scale: 1,
  transition: {
   type: "spring",

   duration: 0.5,
   stiffness: 15,
  },
 },
};
const cardVariantsbg: Variants = {
 offscreen: {
  x: "50vw",
  opacity: 0,
  scale: 0,
  // rotateY: -180,
 },
 onscreen: {
  x: 0,
  opacity: 1,
  scale: 1,
  // scale: 1,
  transition: {
   type: "spring",

   duration: 0.5,
   stiffness: 15,
  },
 },
};

const stage1: Variants = {
 offscreen: {
  scale: 0.1,
  opacity: 0,
  // rotateY: -180,
 },
 onscreen: {
  scale: 1,
  opacity: 1,
  // scale: 1,
  transition: {
   type: "spring",
   duration: 0.5,
   stiffness: 15,
   delay: 1,
  },
 },
};
const stage2: Variants = {
 offscreen: {
  scale: 0.1,
  opacity: 0,
  // rotateY: -180,
 },
 onscreen: {
  scale: 1,
  opacity: 1,
  // scale: 1,
  transition: {
   type: "spring",
   duration: 0.5,
   stiffness: 15,
   delay: 2,
  },
 },
};
const stage3: Variants = {
 offscreen: {
  scale: 0.1,
  opacity: 0,
  // rotateY: -180,
 },
 onscreen: {
  scale: 1,
  opacity: 1,
  // scale: 1,
  transition: {
   type: "spring",
   duration: 0.5,
   stiffness: 15,
   delay: 3,
  },
 },
};
const stage4: Variants = {
 offscreen: {
  scale: 0.1,
  opacity: 0,
  // rotateY: -180,
 },
 onscreen: {
  scale: 1,
  opacity: 1,
  // scale: 1,
  transition: {
   type: "spring",
   duration: 0.5,
   stiffness: 15,
   delay: 4,
  },
 },
};
const stage5: Variants = {
 offscreen: {
  scale: 0.1,
  opacity: 0,
  // rotateY: -180,
 },
 onscreen: {
  scale: 1,
  opacity: 1,
  // scale: 1,
  transition: {
   type: "spring",
   duration: 0.5,
   stiffness: 15,
   delay: 5,
  },
 },
};

function App() {
 const [active, setActive] = useState(false);
 const [scroll, setScroll] = useState(true);
 const [zoomOutStage, setZoomOutStage] = useState(false);
 const variantsZoomOutStage: Variants = {
  open: {
   scale: 300,
   x: -90,
   y: 300,
   x1: 0,

   //  opacity: 0,
   transition: {
    type: "spring",
    duration: 2,
    // staggerChildren: 0.2,
   },
   transitionEnd: { zIndex: 0 },
  },
  // closed: { opacity: 0, x: "-25%" },
 };
 const variantsZoomInStage1: Variants = {
  open: {
   scale: 1,
   opacity: 1,
   x: 0,
   y: 0,

   transition: {
    type: "spring",
    duration: 2,
    // stiffness: 2,
    delay: 0.3,
   },
   //  transitionEnd: { zIndex: 999 },
  },
  closed: { opacity: 0, scale: 10, zIndex: 0 },
 };
 const animationLayer: Variants = {
  open: {
   display: "block",
   transition: {
    type: "spring",
    delay: 0.3,
   },
   //  transitionEnd: { zIndex: 999 },
  },
  closed: { opacity: 0, scale: 10, display: "none" },
 };

 return (
  <StyledAnimation>
   <div className='session-1'>
    <motion.div
     layout
     data-isOpen={active}
     initial={{ borderRadius: 0 }}
     transition={{
      opacity: { ease: "linear" },
      layout: { duration: 0.8 },
     }}
     className='parent'

     // onClick={() => setActive(!active)}
    >
     <p
      className={`${active ? "" : "active"}`}
      onClick={() => setActive(!active)}
     >
      XEM THÊM
     </p>
     {active && (
      <motion.div initial='offscreen' whileInView='onscreen' className='stages'>
       <></>
       <motion.div
        onClick={() => {
         window.scrollTo({ top: 1000, behavior: "smooth" });
        }}
        variants={stage1}
        className='stage-item 1'
       >
        <span className='label'>Hành trang lên đường</span>
        <motion.div
         //  whileHover={{ scale: 1.1 }}
         //  transition={{ type: "spring", stiffness: 400, damping: 10 }}
         className='icon_item'
         onClick={() => setZoomOutStage(true)}
        >
         <motion.div
          animate={zoomOutStage ? "open" : "closed"}
          variants={variantsZoomOutStage}
         >
          <ZondiconsLocation className='icon' />
         </motion.div>
        </motion.div>
       </motion.div>
       <motion.div variants={stage2} className='stage-item 2'>
        <span className='label'>Xa mạc nắng cháy</span>
        <motion.div
         whileHover={{ scale: 1.1 }}
         transition={{ type: "spring", stiffness: 400, damping: 10 }}
         className='icon_item'
        >
         <ZondiconsLocation className='icon' />
        </motion.div>
       </motion.div>
       <motion.div variants={stage3} className='stage-item 2'>
        <span className='label'>Lãnh Nguyên gios rát</span>
        <motion.div
         whileHover={{ scale: 1.1 }}
         transition={{ type: "spring", stiffness: 400, damping: 10 }}
         className='icon_item'
        >
         <ZondiconsLocation className='icon' />
        </motion.div>
       </motion.div>
       <motion.div variants={stage4} className='stage-item 2'>
        <span className='label'>Cánh rừng sâu thẳm</span>
        <motion.div
         whileHover={{ scale: 1.1 }}
         transition={{ type: "spring", stiffness: 400, damping: 10 }}
         className='icon_item'
        >
         <ZondiconsLocation className='icon' />
        </motion.div>
       </motion.div>
       <motion.div variants={stage5} className='stage-item 2'>
        <span className='label'>Nơi "Đất" hóa vàng</span>
        <motion.div
         whileHover={{ scale: 1.1 }}
         transition={{ type: "spring", stiffness: 400, damping: 10 }}
         className='icon_item'
        >
         <ZondiconsLocation className='icon' />
        </motion.div>
       </motion.div>
      </motion.div>
     )}
    </motion.div>
   </div>
   <motion.div
    style={{
     position: "fixed",
     top: "0",
     left: "0",
     width: "100vw",
     height: "100vh",
     zIndex: 99,
    }}
    animate={zoomOutStage ? "open" : "closed"}
    variants={variantsZoomInStage1}
   >
    <div
     style={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
     }}
    >
     <img
      style={{
       zIndex: 1,
       position: "absolute",
      }}
      src='images/background.png'
      alt=''
     ></img>
     {zoomOutStage && <Framer1Component />}
    </div>
   </motion.div>
  </StyledAnimation>
 );
}

export default App;
