import styled from "styled-components";

export const StyledAnimation = styled.div`
 position: relative;
 max-width: 100vw;
 scroll-behavior: smooth;
 overflow: hidden;
 max-height: 100vh;
 overflow: -moz-scrollbars-none;
 -ms-overflow-style: none;
 &::-webkit-scrollbar {
  display: none;
 }

 /* display: flex;
 align-items: center;
 justify-content: center; */
 .session-1 {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .parent {
   background-image: url("images/background.jpg");
   background-size: cover;
   width: 20vw;
   height: 20vh;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   &[data-isOpen="true"] {
    width: 100vw;
    height: 100vh;
   }
   .stages {
    width: 100%;
    height: 100%;
    position: relative;
    .stage-item {
     position: absolute;
     z-index: 3;
     &:first-child {
      top: 80%;
      left: 50%;
     }
     &:nth-child(2) {
      top: 70%;
      left: 20%;
     }
     &:nth-child(3) {
      top: 50%;
      left: 60%;
     }
     &:nth-child(4) {
      top: 40%;
      left: 30%;
     }
     &:nth-child(5) {
      top: 10%;
      left: 50%;
     }
     display: flex;
     flex-direction: column;
     align-items: center;
     cursor: pointer;
     /* transition: transform 0.4s ease;
     &:hover {
      transform: scale(1.1);
     } */
     .label {
      color: white;
      font-size: 20px;
      font-weight: 500;
     }
     .icon_item {
      width: 50px;
      height: 50px;
     }

     .icon {
      color: white;
      width: 100%;
      height: 100%;
     }
    }
    div {
     /* gap: 40px;
   max-width: 1024px;
   width: 100%;
   display: grid;
   grid-template-columns: repeat(5, 1fr);
   align-items: center;
   justify-content: center;
   position: absolute;
   z-index: 3;
   p {
    height: 100px;
    width: 100%;
    background-color: white;
    display: block;
    border-radius: 10px;
   }
  } */
    }
   }

   p {
    background-color: red;
    color: white;
    z-index: 99;
    cursor: pointer;
   }
  }
 }
 .background {
  width: 30%;
  height: 30%;
  overflow: hidden;
  position: relative;
 }
 .framer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  img {
   /* position: absolute;
   bottom: 0;
   right: 0; */
   width: 100%;
   height: 100%;
  }
 }
 .bg-stage-1 {
  width: 10px;
  height: 10px;
  opacity: 0;
  position: fixed;
  z-index: 999;
  &[data-isOpen="true"] {
   top: 0;
   left: 0;
   opacity: 1;
   width: 100vw;
   height: 100vh;
  }
 }
`;
