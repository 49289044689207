export const FlowbiteMapLocationOutline = (props: any) => (
 <svg
  xmlns='http://www.w3.org/2000/svg'
  width='1em'
  height='1em'
  viewBox='0 0 24 24'
  {...props}
 >
  <g
   fill='none'
   stroke='currentColor'
   strokeLinecap='round'
   strokeLinejoin='round'
   strokeWidth='2'
  >
   <path d='M12 13a3 3 0 1 0 0-6a3 3 0 0 0 0 6'></path>
   <path d='M17.8 14a7 7 0 1 0-11.5 0h0l.1.3l.3.3L12 21l5.1-6.2l.6-.7l.1-.2Z'></path>
  </g>
 </svg>
);

export const EiLocation = (props: any) => (
 <svg
  xmlns='http://www.w3.org/2000/svg'
  width='1em'
  height='1em'
  viewBox='0 0 50 50'
  {...props}
 >
  <path
   fill='currentColor'
   d='m25 42.5l-.8-.9C23.7 41.1 12 27.3 12 19c0-7.2 5.8-13 13-13s13 5.8 13 13c0 8.3-11.7 22.1-12.2 22.7l-.8.8zM25 8c-6.1 0-11 4.9-11 11c0 6.4 8.4 17.2 11 20.4c2.6-3.2 11-14 11-20.4c0-6.1-4.9-11-11-11z'
  ></path>
  <path
   fill='currentColor'
   d='M25 24c-2.8 0-5-2.2-5-5s2.2-5 5-5s5 2.2 5 5s-2.2 5-5 5zm0-8c-1.7 0-3 1.3-3 3s1.3 3 3 3s3-1.3 3-3s-1.3-3-3-3z'
  ></path>
 </svg>
);
export const ZondiconsLocation = (props: any) => (
 <svg
  xmlns='http://www.w3.org/2000/svg'
  width='1em'
  height='1em'
  viewBox='0 0 20 20'
  {...props}
 >
  <path
   fill='currentColor'
   d='M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4a2 2 0 0 0 0 4z'
  ></path>
 </svg>
);
