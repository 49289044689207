import React from "react";
import { motion, Variants } from "framer-motion";

const cardVariants1_5: Variants = {
 offscreen: {
  y: 500,
  opacity: 0,
 },
 onscreen: {
  y: 0,
  opacity: 1,
  // scale: 1,
  transition: {
   type: "spring",

   duration: 0.5,
   delay: 1,
   stiffness: 15,
  },
 },
};
const cardVariants1_4: Variants = {
 offscreen: {
  x: 1000,
  opacity: 0,
 },
 onscreen: {
  x: 10,
  opacity: 1,
  transition: {
   type: "spring",
   duration: 0.5,
   delay: 1,
   stiffness: 10,
  },
 },
};
const cardVariants1_3: Variants = {
 offscreen: {
  x: "-100vw",
  opacity: 0,
  // rotateY: -180,
 },
 onscreen: {
  x: -10,
  opacity: 1,
  // scale: 1,
  transition: {
   type: "spring",

   duration: 0.5,
   delay: 1,
   stiffness: 15,
  },
 },
};

const Framer1Component = () => {
 return (
  <motion.div
   id='session1'
   className='card-container'
   initial='offscreen'
   whileInView='onscreen'
   style={{
    scrollBehavior: "smooth",
   }}
   viewport={{ once: true, amount: 0.1 }}
  >
   <div className='framer'>
    <motion.img
     style={{
      zIndex: 3,
      position: "absolute",
     }}
     src='images/man_on_mountain.png'
     variants={cardVariants1_3}
    ></motion.img>
    <motion.img
     style={{
      zIndex: 2,
      position: "absolute",
     }}
     src='images/jungle4.png'
     variants={cardVariants1_4}
    ></motion.img>
    <motion.img
     style={{
      zIndex: 4,
      position: "absolute",
     }}
     src='images/jungle5.png'
     variants={cardVariants1_5}
    ></motion.img>
   </div>
  </motion.div>
 );
};

export default Framer1Component;
